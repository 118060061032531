export default {
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  cn: '简体中文',
  vi: 'Tiếng Việt',
  // navbar
  buyEGT: 'Mua EGT',
  sellEGT: 'Đổi ETH',
  swapEGT: 'Đổi ETH-EGT',
  calculator: 'Bộ chuyển đổi EGT',
  community: 'Cộng đồng của tôi',
  // home
  homeTitle: 'Giao dịch cho vay thông qua hợp đồng thông minh Thoải mái sử dụng tài sản kỹ thuật số',
  homeSubtitle: 'Bắt đầu một cuộc sống đầu tư khác',
  toSwap: 'Đi đến TBT SWAP ngay bây giờ',
  successfullyBorrowAmount: 'Số tiền vay thành công',
  borrowMarket: 'Thị trường vay {token}',
  title: ['EGT Defi', 'Nền tảng giao dịch vay và cho vay'],
  // index
  indexTitle: 'Ứng dụng thương mại vay và cho vay thông qua hợp đồng thông minh',
  indexSubtitle: 'Hợp đồng thông minh. Phi tập trung. Liên kết Ethereum. Cho vay P2P',
  indexDesc: 'EDT là một ứng dụng kinh doanh cho vay P2P hoàn toàn phi tập trung trên blockchain, kết hợp hợp đồng thông minh và liên kết với Ethereum để đảm bảo sự công bằng, minh bạch và công khai, giúp mọi người có thể tham gia đầu tư và vay mượn một cách an tâm, tin tưởng và linh hoạt.',
  investAmount: 'Số tiền đã được vay',
  login: 'Đăng nhập',
  logout: 'Đăng xuất',
  // 貸款
  toBorrow: 'Tôi muốn vay tiền',
  borrowRule: 'Quy định cho vay',
  borrowRuleText: [
    'Người cho vay cần phải đặt tài sản thế chấp vào ví tiền điện tử trên blockchain trước khi điền vào đơn vay và nộp đơn xin vay',
    'Sau khi thành công trong việc gửi đơn xin vay, tài sản thế chấp sẽ được chuyển vào hợp đồng thông minh để lưu giữ, và bạn có thể thấy hợp đồng vay đó trong "Khoản vay của tôi" và "Danh sách đầu tư". Trước khi kết hợp thành công, người cho vay có thể tuỳ ý chỉnh sửa nội dung của khoản vay',
    'Sau khi kết hợp thành công, ví blockchain của người cho vay sẽ nhận được số tiền cho vay sau khi trừ lãi suất,và hợp đồng cho vay sẽ hiển thị trong "Khoản vay của tôi".',
    'Người vay có thể thanh toán bất kỳ lúc nào trước khi hợp đồng vay hết hạn. Sau khi thanh toán thành công, hợp đồng thông minh sẽ tự động trả lại tài sản thế chấp vào ví tiền điện tử trên blockchain của người vay',
    'Nếu người vay không thanh toán vào thời hạn của hợp đồng vay, tài sản thế chấp sẽ bị hợp đồng thông minh thu giữ và quyền sở hữu sẽ được chuyển cho nhà đầu tư của hợp đồng vay đó',
    'Phí khai thác BNB phát sinh từ các hoạt động đã nêu phía trên phải do người vay tự chịu.'
  ],
  myLoans: 'Khoản vay của tôi',
  createLoan: 'Đăng ký vay tiền',
  // 投資
  toDeposit: 'Tôi muốn đầu tư',
  depositRule: 'Quy định đầu tư',
  depositRuleText_usdt: [
    'Trước khi đầu tư, trước tiên nhà đầu tư phải lưu trữ TBT trong ví blockchain, sau đó tìm kiếm hợp đồng đầu tư trong "Danh sách đầu tư" và đăng ký đầu tư.',
    'Sau khi đầu tư thành công, TBT sẽ được tự động cho vay cho người vay và bạn có thể thấy hợp đồng đầu tư đó trong mục "Đầu tư của tôi".',
    'Trước khi hợp đồng đầu tư hết hạn, nếu người vay thanh toán trả nợ, số tiền vay và lãi suất sẽ tự động chuyển vào ví blockchain của nhà đầu tư.',
    'Khi hợp đồng đầu tư đến hạn, nếu người vay không thanh toán trả nợ, 90% của tài sản thế chấp sẽ được chuyển cho nhà đầu tư và 10% còn lại sẽ được chuyển cho nhà cung cấp dịch vụ. Nhà đầu tư có thể yêu cầu thu hồi tài sản thế chấp và lãi suất từ hợp đồng đầu tư đó.',
    'Phí khai thác BNB phát sinh từ các hoạt động đã nêu phía trên phải do nhà đầu tư tự chịu.'
  ],
  myDeposit: 'Đầu tư của tôi',
  orderList: 'Danh sách đầu tư',
  // defi registry
  registryBorrow: 'Trước khi vay tiền cần đăng ký trước',
  registryDeposit: 'Trước khi đầu tư phải đăng ký trước',
  enterReferer: 'Vui lòng nhập địa chỉ được đề xuất',
  // egt
  enterEthAmount: 'Nhập số lượng ETH',
  enterExchangeEthAmount: 'Vui lòng nhập số lượng ETH muốn đổi',
  enterEgtAmount: 'Nhập số lượng EGT',
  enterExchangeEgtAmount: 'Vui lòng nhập số lượng EGT muốn đổi',
  enterBSCEgtAmount: 'Nhập số lượng BSC-EGT',
  canChange: ' Có thể đổi thành',
  piece: 'viên',
  pieceBack: '',
  buy: 'Mua',
  egtExchangeRate: '({value} đổi)',
  registryEgt: 'Trước khi mua phải đăng ký trước',
  registry: 'Đăng ký',
  sellApprove: 'Trước khi đổi vui lòng mở khóa trước',
  sell: 'Đổi',
  sendBscEGT: 'Phát hành ETH-EGT',
  // bridge
  userAddress: 'Địa chỉ người dùng',
  enterBridgeAmount: 'Vui lòng nhập số lượng ETH-EGT muốn phát hành',
  sendToken: 'Phát hành',
  // other
  yourAddress: 'Địa chỉ nhận thanh toán của bạn',
  backToIndex: 'Quay về trang chủ',
  gasNowEstimate: 'Ước tính phí giao dịch hiện tại',
  gasWarning: '(Ước tính phí giao dịch này chỉ mang tính tham khảo. Phí thực tế tùy thuộc vào mức tiêu thụ khi thực hiện hợp đồng)',
  priceUpdated: 'Giá cập nhật vào',
  filter: 'Chọn lọc',
  id: 'Mã số',
  loanDays: 'Thời hạn vay',
  loanToken: 'Loại tiền tệ thế chấp',
  loanTokenAmount: 'Số lượng tài sản thế chấp',
  loanAmount: 'Số tiền vay',
  loanMortgage: 'Tỷ lệ cho vay',
  loanRate: 'Lãi suất',
  loanInterest: 'Lãi suất',
  marketValue: 'Giá trị thị trường',
  APR: 'Tỷ suất lợi nhuận hàng năm',
  waiting: 'Đợi kết hợp',
  repay: 'Hoàn trả',
  breach: 'Vi phạm hợp đồng',
  loaning: 'Đang trong quá trình cho vay',
  approve: 'Vui lòng mở khóa trước khi đăng ký',
  more: 'Lớn hơn',
  less: 'Nhỏ hơn',
  invest: 'Đầu tư',
  noRecord: 'Không có hồ sơ liên quan',
  day: 'Ngày',
  hour: 'Giờ',
  min: 'Phút',
  sec: 'Giây',
  contract: 'Hợp đồng',
  expired: 'Hết hạn',
  buffer: 'Thời gian chờ duyệt',
  due: 'Đến hạn',
  status: 'Tình trạng hợp đồng',
  withdraw: 'Thu hồi',
  edit: 'Chỉnh sửa',
  cancel: 'Hủy bỏ',
  payback: 'Hoàn trả',
  apply: 'Yêu cầu',
  editWarning: 'Việc chỉnh sửa sẽ 【hủy bỏ】 đơn hàng đó và 【tạo ra】 đơn hàng mới',
  editSubWarning: '(Hủy và tạo lại đều phải trả phí giao dịch)',
  // calculator
  swapCalculateTitle: '{token1} đổi {token2}',
  change: 'Đổi',
  amount: 'Số lượng',
  dollar: 'Đồng',
  dataUpdated: 'Dữ liệu cập nhật vào',
  calculate: 'Tính toán',
  // community
  refererAmount: 'Số người giới thiệu trực tiếp của tôi',
  communityAmount: 'Số người trong cộng đồng của tôi',
  // ADT
  adt: 'Chia sẻ Lợi nhuận Thiên thần ADT',
  holding: 'Số lượng nắm giữ',
  claimable: 'Số lượng có thể nhận được',
  claim: 'Nhận',
  // gas fee
  gasPage: 'Hỗ trợ Gas',
  date: 'Ngày',
  from: 'Bắt đầu',
  to: 'Kết thúc',
  applyAddress: 'Địa chỉ người yêu cầu',
  search: 'Tìm kiếm',
  total: 'Tổng cộng',
  subsidy: 'Phụ cấp',
  subsidyTotal: 'Tổng số phụ cấp',
  send: 'Gửi đi',
  // warning
  warning: 'Lưu ý quan trọng',
  warningRegistry: '1. Vui lòng đăng ký trước khi thực hiện các thao tác, nếu không đăng ký có thể dẫn đến giao dịch thất bại.',
  warningBorrow: '2. Giá trị thị trường của tài sản thế chấp hiển thị trong danh sách đầu tư chỉ mang tính chất tham khảo, không được coi là nguồn cơ sở duy nhất khi ra quyết định. Nền tảng này không chịu trách nhiệm thông báo về giá trị chính xác của tài sản thế chấp!',
  warningDeposit: '2. Giá trị thị trường tài sản thế chấp trong danh sách đầu tư chỉ mang tính chất tham khảo, không được coi là cơ sở duy nhất khi ra quyết định của cả người cho vay và người vay, nền tảng này không chịu trách nhiệm thông báo về giá trị chính xác của tài sản thế chấp!',
  warningCalculate: 'Vui lòng tính giá trị thị trường của tài sản thế chấp trong "Bộ chuyển đổi EGT".',
  toRegistry: 'Đi đến đăng ký',
  toCalculator: 'Đi đến Bộ chuyển đổi EGT',
  agree: 'Tôi đã hiểu các hướng dẫn trên và đồng ý đánh dấu vào ô đồng ý.',
  confirm: 'Xác nhận',
  // toasted
  loginFirst: 'Vui lòng đăng nhập trước',
  installMetamask: 'Vui lòng cài đặt MetaMask',
  changeMainnet: 'Vui lòng chuyển sang chuỗi thông minh Binance',
  changeETH: 'Vui lòng chuyển sang mạng chính Ethereum',
  connectionBreak: 'Kết nối MetaMask bị gián đoạn, vui lòng đăng nhập lại',
  changeWallet: 'Đã chuyển đổi kết nối ví',
  waitApprove: 'Đang mở khóa, vui lòng đợi một chút',
  waitRegistry: 'Đang đăng ký, vui lòng đợi một chút',
  waitClaim: 'Đang rút, vui lòng đợi một chút',
  txSend: 'Giao dịch đã được gửi đi',
  userRefuse: 'Người dùng từ chối kết nối',
  addressHasUsed: 'Địa chỉ này đã được đăng ký',
  errorOccured: 'Đã xảy ra lỗi',
  USDTUnderBalance: 'Số dư USDT không đủ',
  TBTUnderBalance: 'Số dư TBT không đủ',
  underBalance: 'Số dư không đủ',
  cannotGetGas: 'Không thể tính phí giao dịch',
  renewGetGas: 'Kết nối WebSocket bị gián đoạn, vui lòng làm mới trang web để nhận phí giao dịch ước tính mới nhất.',
  renew: 'Kết nối WebSocket bị gián đoạn, vui lòng làm mới trang web',
  waitGetData: 'Đang lấy thông tin từ chuỗi, vui lòng đợi một chút',
  noReward: 'Không có lợi nhuận nào có thể rút được',
  cannotGetEthValue: 'Không thể lấy được giá trị thị trường ETH',
  cannotGetData: 'Lấy dữ liệu thất bại',
  selectTx: 'Vui lòng chọn giao dịch cần hỗ trợ',
  txError: 'Giao dịch gặp sự cố',
  agreeFirst: 'Vui lòng đánh dấu đồng ý',
  cannotGetMarketValue: 'Không thể tính giá trị thị trường',
  // other
  cannotFindPage: 'Không tìm thấy trang web',
  webConstructing: 'Trang web đang được xây dựng ',
  webConstructingDescription: 'Rất tiếc, trang web bạn truy cập đang được xây dựng, chức năng chưa được mở.',
}