export default {
  test: '測試測試測試',
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  cn: '简体中文',
  vi: 'Tiếng Việt',
  // navbar
  gamePool: 'Prize Pool',
  buyEGT: 'Buy EGT',
  sellEGT: 'Swap for ETH',
  swapEGT: 'Swap for ETH-EGT',
  calculator: 'EGT Unit Converter',
  community: 'My Community',
  // home
  homeTitle: 'Smartly Manage and Secure Your Assets with P2P Lending Based on Smart Contract.',
  homeSubtitle: 'INVEST SMARTLY AND SAFELY',
  toSwap: 'TBT SWAP',
  successfullyBorrowAmount: 'Total Borrowed',
  borrowMarket: '{token} MARKET',
  title: ['', 'Crypto Lending Platform'],
  // index
  indexTitle: 'P2P Lending Based on Smart Contracts for Commercial Purpose',
  indexSubtitle: 'Smart Contracts．Decentralization．Run on the Ethereum blockchain．P2P Lending',
  indexDesc: 'EDT, a completely decentralized peer-to-peer lending built on blockchain for commercical purpose, bundles with smart contracts and runs on Ethereum in order to be impartial, fair and transparent. It is trustworthy and worry-free for everyone to invest and borrow.',
  investAmount: 'Total Borrowed',
  gamePoolAmount: 'Current Total Prize Pool',
  login: 'Log in',
  logout: 'Log out',
  // 貸款
  toBorrow: 'Borrow',
  borrowRule: 'How to Apply for Loans',
  borrowRuleText: [
    'Befor applying for loans, the borrower shall deposit your collateral in your blockchain wallet. Then, complete the form to submit the loan application.',
    'After you successfully submit the application, the collateral will be kept in smart contract, and you will find the loan contract on My Loans and Investment List waiting for matchmaking. Before getting matched, the borrower can always amend your content anytime',
    'After you get matched, the borrower\'s blockchain wallet will receive the loan proceeds(interest deduction) and you will find the loan contract on My Loans.',
    'The borrower can repay the loan anytime before the due date. After the borrower successfully repays the loan, the smart contract will automatically return the collateral to the borrower\'s blockchain wallet.',
    'When the contract expires, if the borrower defaults on the loan, the collateral will be seized by smart contract and the investor has the right to obtain ownership of the collateral.',
    'The borrower shall bear the gas fee involved in the transactions mentioned above.'
  ],
  myLoans: 'My Loans',
  createLoan: 'Apply for Loans',
  // 投資
  toDeposit: 'Supply',
  depositRule: 'How it works?',
  depositRuleText_usdt: [
    'Before supplying, a supplier shall deposit USDT in your blockchain wallet. Then, click the \'Supply List\' to provide the asset and amount based on the market borrowing demand.',
    'Once the supplier provides funds to the borrower, and the transaction is executed, USDT will be automatically transferred to the borrower by smart contract. The supplier will find the supply information on \'My Supplies\'.',
    'If the borrower has repaid the loan by the time the contract expires, the supplier\'s blockchain wallet will receive the total amount of the loan and its interests.',
    'However, if the borrower defaults on the loan when the contract expires, the supplier has the right to claim 90% of the collateral and 10% of which will be seized by the platform.',
    'The supplier shall pay the gas fee when executing the transactions mentioned above.'
  ],
  depositRuleText_tbt: [
    'Before supplying, a supplier shall deposit TBT in your blockchain wallet. Then, click the \'Supply List\' to provide the asset and amount based on the market borrowing demand.',
    'Once the supplier provides funds to the borrower, and the transaction is executed, TBT will be automatically transferred to the borrower by smart contract. The supplier will find the supply information on \'My Supplies\'.',
    'If the borrower has repaid the loan by the time the contract expires, the supplier\'s blockchain wallet will receive the total amount of the loan and its interests.',
    'However, if the borrower defaults on the loan when the contract expires, the supplier has the right to claim 90% of the collateral and 10% of which will be seized by the platform.',
    'The supplier shall pay the gas fee when executing the transactions mentioned above.'
  ],
  myDeposit: 'My Supplies',
  orderList: 'Supply List',
  // defi registry
  registryBorrow: 'Register before borrowing',
  registryDeposit: 'Register before investing',
  enterReferer: 'Your Referrer',
  // egt
  enterEthAmount: 'Enter the ETH Quantity',
  enterExchangeEthAmount: 'Enter the Desired ETH Quantity',
  enterEgtAmount: 'Enter the EGT Quantity',
  enterExchangeEgtAmount: 'Enter the Desired EGT Quantity',
  enterBSCEgtAmount: 'Enter the BSC-EGT Quantity',
  canChange: 'Get',
  piece: 'Unit(s) of',
  pieceBack: '',
  buy: 'buy',
  egtExchangeRate: '(Exchange Rate: {value})',
  registryEgt: 'Register before buying',
  registry: 'Register',
  sellApprove: 'Unlock before Swapping',
  sell: 'Swap',
  sendBscEGT: 'Sending ETH-EGT',
  // bridge
  userAddress: 'User\'s Address',
  enterBridgeAmount: 'Enter the Desired ETH-EGT Quantity',
  sendToken: 'Send',
  // game
  luckyPool: 'Lucky Prize Pool',
  countdown: 'Countdown',
  noOrder: 'Currently No Order',
  drawing: 'Drawing',
  currRound: 'Round',
  newLuckyAddress: 'The Latest Lucky Address',
  luckyPoolRule: 'Lucky Pool Rules',
  luckyPoolRuleText: [
    'Each Prize Bracket has its allotted time frame, and thus each round will be based on the Countdown shown above. Each allotted time frame will be based on the Prize Brackets List shown below. If a new loan contract was carried out, the Countdown will be recalculated based on the new Rollover and its allotted time. By the time the Countdown finishes off, if there is no new loan contract, the round will be finished off and the lucky address will have all the prize. ',
    'Lucky Address: When Countdown ends, the address of the borrower who happens to be the last borrower who successfully matches the loan contract.\nLet me take myself as an example. When the Rollover is more than 100,000, and its allotted time frame is 10 minutes, if I successfully match the loan contract (no matter how much I apply for or how much interest it attaches), my address will be the the Latest Lucky Address. As a result, the Countdown will start from 10 minutes. By the time the Countdown ends, if there is no other new matched borrower, my address will be the Lucky Address and have all the prize. However, before the Countdown ends, there is another new borrower who successfully matches the loan contract, the Countdown will restart from 10 minutes, and he will be the winner while I have no chance to win since his address is currently the Latest Lucky Address.'
  ],
  stepTable: 'Prize Brackets',
  time: 'Time',
  blockchainBased: 'based on block time',
  accumPoolAmount: 'Rollover',
  rankPool: 'Battle Prize Pool',
  myRankAmount: 'My Battle Fees',
  rankPoolRule: 'Rules',
  rankPoolRuleText_usdt: [
    'The Battle Pool will take 30 days for a new round, and the exact time of each round will be based on the Countdown shown above. When the round ends, the player with the highest Battle Fees will have the 50% of the Battle Prize Pool, and the rest of the 50% will be proportionally distributed to other 49 players (from second rank to 50th rank).',
    'Battle Fees: When you apply for an loan, and the loan contract successfully get matched, its interest will be accumulated as your Battle Fees. However, the same loan and its interest cannot be used for the next round.\nLet me take myself as an example. In the round, I apply for the first loan and successfully get matched, so the interest coming along is 100USDT. Then I apply for the second loan, and the interest is 200 USDT. As a result, my Battle Fees will be 300USDT (100+200). If my Battle Fees in the round are the highest one, then I will get to have 50% of the Battle Prize Pool. But if my Battle Fees are not the highest but still in Top 50, then Prize Pool will be proportionally distributed based on my ranking.'
  ],
  rankPoolRuleText_tbt: [
    'The Battle Pool will take 30 days for a new round, and the exact time of each round will be based on the Countdown shown above. When the round ends, the player with the highest Battle Fees will have the 50% of the Battle Prize Pool, and the rest of the 50% will be proportionally distributed to other 49 players (from second rank to 50th rank).',
    'Battle Fees: When you apply for an loan, and the loan contract successfully get matched, its interest will be accumulated as your Battle Fees. However, the same loan and its interest cannot be used for the next round.\nLet me take myself as an example. In the round, I apply for the first loan and successfully get matched, so the interest coming along is 100TBT. Then I apply for the second loan, and the interest is 200 TBT. As a result, my Battle Fees will be 300TBT (100+200). If my Battle Fees in the round are the highest one, then I will get to have 50% of the Battle Prize Pool. But if my Battle Fees are not the highest but still in Top 50, then Prize Pool will be proportionally distributed based on my ranking.'
  ],
  rank: 'Rank',
  address: 'Address',
  rankAmount: 'Fees',
  // other
  yourAddress: 'Your Receiving Address',
  backToIndex: 'Return to Homepage',
  gasNowEstimate: 'Currently Estimated Gas Fee',
  gasWarning: '(The estimated gas fee serves as a reference only. The actual gas fee shall be based on the costs required to execute a contract.)',
  priceUpdated: 'The prices were last updated: ',
  filter: 'Filter',
  id: 'No. ',
  loanDays: 'Due within',
  loanToken: 'Collateral Token',
  loanTokenAmount: 'Collateral Amount',
  loanAmount: 'Loan Amount',
  loanMortgage: 'LTV',
  loanRate: 'Interest Rate',
  loanInterest: 'Interests',
  marketValue: 'Market Value',
  APR: 'APR',
  waiting: 'Pending',
  repay: 'Repaid',
  breach: 'In default',
  loaning: 'Ongoing',
  approve: 'Unlock before Applying',
  more: 'More than',
  less: 'Less than',
  invest: 'Supply',
  noRecord: 'No Relevant Record',
  day: 'days',
  hour: ' hrs',
  min: ' mins',
  sec: ' secs',
  contract: 'The contract ',
  expired: 'has expired',
  buffer: 'is in grace period',
  due: 'has expired',
  status: 'Status',
  withdraw: 'seize the collateral',
  edit: 'edit',
  cancel: 'cancel',
  payback: 'Repay',
  apply: 'Apply',
  editWarning: '\'Edit\' will CANCEL the order and have it reorder',
  editSubWarning: '(Whether you cancel or place an order, you will have to pay the gas fee.)',
  // calculator
  swapCalculateTitle: 'Swap {token1} for {token2}',
  change: 'Exchange',
  amount: 'Quantity',
  dollar: '',
  dataUpdated: 'The data was last updated: ',
  calculate: 'Calculate',
  vs: ' for ',
  vsEn: 'Swap',
  vsBack: '',
  // community
  refererAmount: 'My Referees',
  communityAmount: 'My Community Members',
  // ADT
  adt: 'ADT',
  holding: 'The Quantity You Hold',
  claimable: 'The Quantity You Get',
  claim: 'Get',
  // gas fee
  gasPage: 'Gas Subsidy',
  date: 'Date',
  from: 'Start Date',
  to: 'End Date',
  applyAddress: 'Applicant\'s Address',
  search: 'Search',
  total: 'Total',
  subsidy: 'Subsidy',
  subsidyTotal: 'Total Amount of Subsidy',
  send: 'Send',
  // warning
  warning: 'NOTICE',
  warningRegistry: '1. Please register before you borrow or supply. If not, you may not be able to process.',
  warningBorrow: '2. Please note that the market value of collateral on the supply information only serves as a reference. The platform will not provide the timely market value of collateral since the market value of the collateral is subject to fluctuation. Borrowers and suppliers shall not consider the market value of the collateral on the platform as the only advice or information for investment. Therefore the platform bears no liability to inform the timely and accurate market value of the collateral.',
  warningDeposit: '2. Please note that the market value of collateral on the supply information only serves as a reference. The platform will not provide the timely market value of collateral since the market value of the collateral is subject to fluctuation. Borrowers and suppliers shall not consider the market value of the collateral on the platform as the only advice or information for investment. Therefore the platform bears no liability to inform the timely and accurate market value of the collateral.',
  warningCalculate: 'The market value of the collateral can be calculated on EGT Unit Converter.',
  toRegistry: 'Register',
  toCalculator: 'Go to EGT Unit Converter',
  agree: 'I have read, understand, and agree to the above rules.',
  confirm: 'Confirm',
  // toasted
  loginFirst: 'Please log in first',
  installMetamask: 'Please download MetaMask first',
  changeMainnet: 'Please switch to Binance Smart Chain(BSC)',
  changeETH: 'Please switch to Ethereum Mainnet',
  connectionBreak: 'Disconnected. Please log in again',
  changeWallet: 'Successfully changed the connected account',
  waitApprove: 'Unlocking, Please wait',
  waitRegistry: 'Registering, Please wait',
  waitClaim: 'Withdrawing, Please wait',
  txSend: 'Transaction Sent',
  userRefuse: 'The user refused to connect',
  addressHasUsed: 'The address has been registered',
  errorOccured: 'Error',
  USDTUnderBalance: 'USDT balance is insufficient',
  TBTUnderBalance: 'TBT balance is insufficient',
  underBalance: 'Insufficient Funds',
  gameOpening: 'Prize Pool Drawing',
  cannotGetGas: 'cannot calculate the gas fee',
  renewGetGas: 'WebSocket get disconnected. Please reload this page to get the latest estimated gas fee',
  renew: 'WebSocket get disconnected. Please reload this page',
  waitGetData: 'Please wait, we are loading data from blockchain',
  noReward: 'Currently No Return',
  cannotGetEthValue: 'data failed: error occured in collecting the market value of ETH',
  cannotGetData: 'data failed: error occured in collecting data',
  selectTx: 'Please choose the transaction you need to subsidize',
  txError: 'trade failed: error occured while trading',
  agreeFirst: 'Please agree to the terms',
  cannotGetMarketValue: 'cannot calculate the market value',
  // other
  cannotFindPage: '找不到網頁',
  webConstructing: '網站建置中',
  webConstructingDescription: '很抱歉您所造訪的網頁正在建置中，功能尚未開放',
}